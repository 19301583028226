@import '../../variables.scss';


.skills-container {
@media only screen and (min-width:600px) {
  margin-top: 1.5rem;

  min-height: fit-content;

  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: 20% 80%;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "skills-header skills-header"
    "group-selector-area skills-area";

  padding: 0;
}

@media only screen and (max-width:600px) {
  margin-top: 1.5rem;

  display: flex;
  flex-direction: column;
}

  .skills-header { 
    grid-area: skills-header; 
    text-align: center;
    text-justify: center;
  }

  .group-selector-area { 
    grid-area: group-selector-area; 
    width: 100%;
  }

  .skills-area { 
    grid-area: skills-area;
  
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  
    align-self: top;
    width: 100%;

    .skill-badge {
      @mixin badge-format {
        margin: 0.5rem;
      }
      
      @include badge-format;

      color: $c_secondary;
      
      &-selected {
        color: $c_accent;

        @include badge-format;
      }
    }
  }
}