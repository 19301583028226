@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

$primary-color: #f4a7b9; // Soft pink
$secondary-color: #f7cac9; // Light coral
$background-color: #fff5f8; // Light blush
$text-color: #d45a6b; // Rose
$accent-color: #e6a6b0; // Light rose
$check-mark-color: #28a745; // Green

body {
    margin: 0;
    padding: 0;
    font-family: 'Lora', serif;
    background-color: $background-color;
}

.security-questions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;

    h2 {
        color: $text-color;
        margin: 10px 0;
        font-weight: 700;
    }

    p {
        color: $text-color;
        margin: 10px 0;
        font-weight: 400;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 300px;
        margin: 20px 0;

        .question-container {
            position: relative;
            margin-bottom: 20px;
            width: 100%;

            label {
                color: $text-color;
                margin-bottom: 10px;
                font-weight: 400;
                display: block;
            }

            input[type="text"] {
                padding: 10px;
                border: 1px solid $primary-color;
                border-radius: 5px;
                width: 100%;
                font-family: 'Lora', serif;
            }

            .check-mark {
                position: absolute;
                right: 10px;
                top: 35px;
                color: $check-mark-color;
                font-size: 20px;
            }
        }
    }
}

.happy-cat {
    position: fixed;
    bottom: -200px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    animation: slide-up 0.5s forwards, slide-down 0.5s forwards 4.5s;
}

@keyframes slide-up {
    to {
        bottom: 20px;
    }
}

@keyframes slide-down {
    to {
        bottom: -200px;
        display: none;
    }
}

.valentines-please-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;

    h2 {
        color: $text-color;
        margin: 10px 0;
        font-weight: 700;
    }

    p {
        color: $text-color;
        margin: 10px 0;
        font-weight: 400;
    }
}