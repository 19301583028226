@import '../../variables.scss';

@media only screen and (max-width:$bp-phone) {
  .highlight-container {  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 1rem;
  }
}

.highlight-choice, .highlight-choice-selected {
  font-size: $font-size-info;
  margin: 0.5rem 0;
}

.highlight-choice {
  color: $c_tertiary;
}

.highlight-choice-selected {
  color: $c_accent;
}