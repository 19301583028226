.valentines-please-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
    height: 100vh;
    background-color: #fff5f8; // Light blush background

    .dog-smile {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
    }

    h2 {
        color: #d45a6b; // Rose color
        margin-bottom: 20px;
        font-family: 'Lora', serif;
    }

    .buttons-container {
        display: flex;
        gap: 20px;
        position: relative;
        width: 100%;
        justify-content: center;
    }

    .yes-button, .no-button {
        padding: 10px 20px;
        font-family: 'Lora', serif;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    .yes-button {
        background-color: #f4a7b9; // Soft pink
        color: white;

        &:hover {
            background-color: #f7cac9; // Light coral
        }
    }

    .no-button {
        background-color: #d45a6b; // Rose color
        color: white;

        &:hover {
            background-color: #e6a6b0; // Light rose
        }

        &:disabled {
            background-color: #ccc;
            cursor: not-allowed;
        }

        &.absolute {
            position: absolute;
        }
    }

    .corgi-container {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;

        img {
            width: 200px;
            height: auto;
        }

        p {
            color: #d45a6b; // Rose color
            font-family: 'Lora', serif;
            margin-top: 10px;
        }
    }

    .cat-bop-container {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        img {
            width: 200px;
            height: auto;
        }

        p {
            color: #d45a6b; // Rose color
            font-family: 'Lora', serif;
            margin-top: 10px;
        }
    }
}