@import '../../variables';

.nav-bar-container {
  position: sticky;
  top: 0;

  span {
    width: 100vw;
    display: flex;
    justify-content: space-between;
  }
}